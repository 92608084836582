<template>
  <div class="job-layout">
    <div @click="openWeapp()"></div>
  </div>
</template>

<script>
export default {
  name: 'urlLink',
  data () {
    return {
      enterpriseId: '',
      jobFairId: '',
      identifyType: ''
    }
  },
  mounted () {
    this.identifyType = this.$myJs.getQueryVariable( 'identifyType' )
    this.enterpriseId = this.$myJs.getQueryVariable( 'enterpriseId' )
    this.jobFairId = this.$myJs.getQueryVariable( 'jobFairId' )
    if ( this.identifyType == 1 ) {
      this.$toast( '您暂无权限' )
    } else if ( this.identifyType == 2 ) {
      this.openWeapp( { enterpriseId: this.enterpriseId, jobFairId: this.jobFairId } )
    }
  },
  methods: {
    openWeapp ( params ) {
      this.$api.home
        .openWeapp( params )
        .then( res => {
          console.log( res )
          location.href = res.data.url_link
        } )
        .catch( () => {

        } )
    }
  }
}
</script>

<style lang="less" scoped>
</style>
